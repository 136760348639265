import React from "react";
import InfoBox from "./InfoBox";
import Header from "./Header";

export default () => (
  <div style={{ maxWidth: "100%" }}>
    <InfoBox>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "space-around",
          padding: "18px",
          boxSizing: "border-box"
        }}
      >
        <div style={{ width: "500px", maxWidth: "90%" }}>
          <Header>
            <h3>
              Building <span style={{ fontWeight: "700" }}>sustainable</span>{" "}
              solutions
            </h3>
          </Header>
        </div>
        <div style={{ width: "500px", maxWidth: "90%" }}>
          <Header>
            <h3>
              Tailored to <span style={{ fontWeight: "700" }}>your needs</span>
            </h3>
          </Header>
        </div>
      </div>
    </InfoBox>
    <InfoBox>
      <Header>
        <h2>
          What is <span style={{ fontWeight: "700" }}>docode?</span>
        </h2>

        <div
          style={{
            width: "600px",
            maxWidth: "80%",
            padding: "14px",
            fontSize: "18px"
          }}
        >
          <div style={{ margin: "8px", textAlign: "center" }}>
            Docode is a small software engineering consulting company in Malmö, Sweden
            which is run by me, myself and I, Daniel Olsson.
          </div>
          <div style={{ margin: "8px", textAlign: "center" }}>
            I hold a master's degree in computer science and engineering from
            Lunds Tekniska Högskola.
          </div>
        </div>
      </Header>
    </InfoBox>
  </div>
);
