import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home";
import About from "./About";

export default props => (
  <Switch>
    <Route exact path="/about" component={About} />
    <Route path="/" component={Home} />
  </Switch>
);
