import React from "react";
import { Link } from "react-router-dom";

import "./navigation.css";

export default props => (
  <Link to={props.path} className="navigation-button">
    {props.children}
  </Link>
);
