import React from "react";

export default props => (
  <div
    style={{
      backgroundColor: "rgba(255,255,255,0.9)",
      marginTop: "20px",
      marginBottom: "20px",
      width: "1170px",
      maxWidth: "100%",
      padding: "30px 15px",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    {props.children}
  </div>
);
