import React, { Component } from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import AppRouter from "./AppRouter";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { dropdownIsShown: false };
    this.onDropdownButtonClicked = this.onDropdownButtonClicked.bind(this);
  }

  onDropdownButtonClicked() {
    this.setState({ dropdownIsShown: !this.state.dropdownIsShown });
  }

  render() {
    return (
      <Router>
        <div className="root-div">
          <div className="root-flexbox">
            <div className="main-title">docode</div>
            <div className="subtitle">Daniel Olsson | Software Engineer</div>
            <NavigationBar
              onDropdownButtonClicked={this.onDropdownButtonClicked}
              dropdownIsShown={this.state.dropdownIsShown}
            />

            <AppRouter />

            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
