import React from "react";
import NavigationButton from "./NavigationButton";

import "./navigation.css";


const NavigationDropdownLine = props => (
  <span
    style={{
      width: "22px",
      height: "2px",
      backgroundColor: "#888",
      ...props.style
    }}
  />
);

export default props => (
  <div className="navigation-bar-root">
    <div className="minimized-navigation-bar-header">
      <div
        style={{
          padding: "15px",
          fontWeight: "900",
          color: "#777",
          fontSize: "20px",
          textTransform: "uppercase",
          letterSpacing: "2px"
        }}
      >
        docode
      </div>
      <div className="dropdown-button" onClick={props.onDropdownButtonClicked}>
        <NavigationDropdownLine />
        <NavigationDropdownLine
          style={{ marginTop: "4px", marginBottom: "4px" }}
        />
        <NavigationDropdownLine />
      </div>
    </div>
    <div className={`dropdown ${props.dropdownIsShown ? "show-dropdown" : "hide-dropdown"}`}>
      <NavigationButton path="/">Home</NavigationButton>
      <NavigationButton path="/about">About</NavigationButton>
    </div>
  </div>
);
