import React from "react";

export default props => (
  <div
    style={{
      margin: "8px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    {props.children}
  </div>
);
